/**
 * @file standalone/offer_banner.scss
 *
 */

@import '../theme-bootstrap';

.offer-banner-formatter {
  margin: 0 auto;
  text-align: center;
  border-top: solid 1px $color-lighter-gray;
  &.slick-slider {
    padding: 0;
  }
  .slick-list {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0;
  }
  .slick-track {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slick-slide {
    margin: 0;
  }
  .slick-arrow {
    z-index: 10;
    margin-top: 0;
    transform: translateY(-50%);
    &::before {
      font-size: 16px;
    }
  }
}

.offer-banner-item {
  text-align: center;
  padding: 10px 20px 14px 20px;
  width: 85%;
  margin: 0 auto;
  &__content {
    position: relative;
  }
  &__image {
    display: inline-block;
    vertical-align: middle;
    height: 42px;
    width: auto;
    position: relative;
    @include breakpoint($medium-up) {
      transform: none;
    }
    &--spacer {
      width: 1px;
    }
  }
  &__text {
    display: inline-block;
    color: $color-medium-dark-gray;
    position: relative;
    vertical-align: middle;
    line-height: 1.25em;
  }
  &__title,
  &__body {
    display: block;
    @include breakpoint($large-up) {
      display: inline;
    }
  }
  &__title {
    font-family: $font--lamer-text;
  }
  p {
    margin: 0;
    @include breakpoint($large-up) {
      display: inline;
    }
  }
}
